import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Input, TextField } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  createSession,
  getInsight,
  loadBotData,
  postFeedback,
} from "../../API/API";
import { makeNotif } from "../Toastify";
import { orderedKeys, user_sample } from "./sample";
import styles from "./whisper.module.css";

export default function Whisper() {
  const [appUsername, setAppUsername] = useState(Cookies.get("username"));
  const [appPassword, setAppPassword] = useState(Cookies.get("password"));
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [userData, setUserData] = useState(user_sample);
  const [sessionId, setSessionId] = useState("");
  const [conversationString, setConversationString] = useState(
    "coach: Hi there! I'm glad you've decided to join this session. How are you feeling today?\nuser: Hi, thanks. I'm feeling quite anxious, to be honest. That's why I'm here.\ncoach: Thank you for sharing that with me. It's great that you're reaching out for support. Can you tell me a bit more about what’s causing your anxiety?\nuser: It's mainly work-related stress. I have a lot of deadlines coming up, and I feel overwhelmed trying to manage everything.\ncoach: That sounds challenging. It’s understandable to feel overwhelmed with so much on your plate. Have you noticed any particular times or situations where your anxiety feels worse?\nuser: Yes, it gets worse in the evenings when I’m trying to relax but can’t stop thinking about everything I need to do the next day.\ncoach: Evenings can definitely be a tough time when you're trying to unwind. Let's work on some strategies to help you manage that anxiety. Have you tried any relaxation techniques or time management strategies before?\nuser: I’ve tried some deep breathing exercises, but they don’t always work. I haven’t really explored any time management techniques, though."
  );
  const [formattedConversation, setFormattedConversation] = useState([]);
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState([
    { case: "depression", flag: false },
    { case: "stress", flag: false },
    { case: "suicide ideation", flag: false },
    { case: "severe depression", flag: false },
    { case: "severe suicide ideation", flag: false },
    { case: "severe stress", flag: false },
    { case: "Substance/Medication Use", flag: false },
  ]);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
    },
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await fetchData("form");
  };

  const fetchData = async (source) => {
    if ((appUsername && appPassword) || source === "form") {
      try {
        const data = await loadBotData(appUsername, appPassword);
        if (data.message !== "Incorrect credentials") {
          Cookies.set("username", appUsername);
          Cookies.set("password", appPassword);
          setInvalidCredentials(false);
          setModalIsOpen(false);
        } else {
          setInvalidCredentials(true);
          setModalIsOpen(true);
          Cookies.remove("username");
          Cookies.remove("password");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setModalIsOpen(true);
        }
      }
    }
  };

  const handleDemographyChange = (field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      message: {
        ...prevData.message,
        demography: { ...prevData.message.demography, [field]: value },
      },
    }));
  };

  const handleLastClinicalIntakeChange = (index, value) => {
    setUserData((prevData) => ({
      ...prevData,
      message: {
        ...prevData.message,
        last_clinical_intake: prevData.message.last_clinical_intake.map(
          (appointment, i) => {
            if (i === index) {
              return {
                ...appointment,
                Answer: value,
              };
            }
            return appointment;
          }
        ),
      },
    }));
  };

  const handleAppointmentChange = (index, field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      message: {
        ...prevData.message,
        appointment_history: prevData.message.appointment_history.map(
          (appointment, i) => {
            if (i === index) {
              return {
                ...appointment,
                [field]: value,
              };
            }
            return appointment;
          }
        ),
      },
    }));
  };

  const handleSubmit = async () => {
    const lines = conversationString.split("\n");
    const conversationLines = lines
      .filter((line) => line.includes(": "))
      .map((line) => {
        const [sender, ...messageParts] = line.split(": ");
        const message = messageParts.join(": ");
        return { sender: sender.trim(), message: message.trim() };
      });

    setFormattedConversation(conversationLines);

    try {
      setLoading(true);
      const createSessionResponse = await createSession(userData);
      const session_id = createSessionResponse.data;
      setSessionId(session_id);
      const getInsightResponse = await getInsight(
        conversationLines,
        session_id
      );
      setFeedback([
        { case: "depression", flag: false },
        { case: "stress", flag: false },
        { case: "suicide ideation", flag: false },
        { case: "severe depression", flag: false },
        { case: "severe suicide ideation", flag: false },
        { case: "severe stress", flag: false },
        { case: "Substance/Medication Use", flag: false },
      ]);
      setResult(getInsightResponse.data.result);
    } catch (error) {
      makeNotif("error", "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleConversationChange = (e) => {
    setConversationString(e.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.informationContainer}>
      <Modal isOpen={modalIsOpen} style={modalStyle}>
        <form className={styles.loginForm} onSubmit={handleLogin}>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            value={appUsername}
            onChange={(e) => setAppUsername(e.target.value)}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={appPassword}
            onChange={(e) => setAppPassword(e.target.value)}
          />
          {invalidCredentials && (
            <p style={{ color: "red", margin: 0 }}>Invalid credentials</p>
          )}
          <button className={styles.button}>Login</button>
        </form>
      </Modal>
      {!modalIsOpen && (
        <>
          <button
            className="button button-progress"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Processing..." : "Process"}
          </button>
          <div className={styles.conversation}>
            <textarea
              placeholder="Conversation"
              value={conversationString}
              onChange={handleConversationChange}
            />
          </div>
          {result && (
            <Result
              results={result}
              sessionId={sessionId}
              conversation={formattedConversation}
              feedback={feedback}
              setFeedback={setFeedback}
            />
          )}
          <div className={styles.container}>
            <Demography
              demography={userData.message.demography}
              handleInputChange={handleDemographyChange}
            />
            <LastClinicalIntake
              last_clinical_intake={userData.message.last_clinical_intake}
              handleInputChange={handleLastClinicalIntakeChange}
            />
            <AppointmentHistory
              appointments={userData.message.appointment_history}
              handleInputChange={handleAppointmentChange}
            />
            <MessageHistory messages={userData.message.message_history} />
          </div>
        </>
      )}
    </div>
  );
}

const Demography = ({ demography, handleInputChange }) => (
  <div className={styles.demography}>
    <h2>Demography</h2>
    {demography &&
      orderedKeys.map((key, index) => (
        <div key={index}>
          <TextField
            label={key.replace(/_/g, " ")}
            value={demography[key] || ""}
            onChange={(e) => handleInputChange(key, e.target.value)}
            fullWidth
          />
        </div>
      ))}
  </div>
);

const LastClinicalIntake = ({ last_clinical_intake, handleInputChange }) => (
  <div className={styles.lastClinicalIntake}>
    <h2>Last Clinical Intake</h2>
    {last_clinical_intake?.map((item, index) => (
      <div key={index}>
        <TextField
          label={item.Question}
          value={item.Answer || ""}
          onChange={(e) => handleInputChange(index, e.target.value)}
          fullWidth
        />
      </div>
    ))}
  </div>
);

const AppointmentHistory = ({ appointments, handleInputChange }) => {
  return (
    <div className={styles.appointmentHistory}>
      <h2>Appointment History</h2>
      {appointments?.map((appointment, index) => (
        <div key={index} className={styles.appointmentContainer}>
          <TextField
            label="LOC"
            value={appointment.LOC || ""}
            onChange={(e) => handleInputChange(index, "LOC", e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Reason for Call"
            value={appointment.reason_for_call || ""}
            onChange={(e) =>
              handleInputChange(index, "reason_for_call", e.target.value)
            }
            fullWidth
            margin="normal"
          />

          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: { items: ["undo", "redo", "|", "bold", "italic"] },
            }}
            data={appointment.clinical_notes || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleInputChange(index, "clinical_notes", data);
            }}
          />
          <hr />
        </div>
      ))}
    </div>
  );
};

const MessageHistory = ({ messages }) => (
  <div className={styles.messageHistory}>
    <h2>Message History</h2>
    {messages?.map((message, index) => (
      <div key={index} className={`${styles.message} ${styles[message.user]}`}>
        <p className={styles.messageDate}>{message.date}</p>
        <div className={styles.messageBubble}>{message.message}</div>
      </div>
    ))}
  </div>
);

const Result = ({
  results,
  sessionId,
  conversation,
  feedback,
  setFeedback,
}) => {
  const [loading, setLoading] = useState(false);

  const cases = [
    "depression",
    "stress",
    "suicide ideation",
    "severe depression",
    "severe suicide ideation",
    "severe stress",
    "Substance/Medication Use",
  ];

  const resultCases = new Set(results.map((result) => result.case));

  const handleCheckbox = (e) => {
    setFeedback((prevFeedback) =>
      prevFeedback.map((item) =>
        item.case === e.target.name ? { ...item, flag: e.target.checked } : item
      )
    );
  };

  const handleSubmitFeedback = async () => {
    setLoading(true);
    try {
      await postFeedback(sessionId, conversation, results, feedback);
      makeNotif("success", "Feedback recorded successfully");
    } catch (err) {
      makeNotif("error", "An error occurred while recording the feedback");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.resultContainer}>
        {cases.map((caseType, idx) => (
          <div
            key={idx}
            className={`${styles.resultBox} ${
              resultCases.has(caseType) ? styles.highlight : ""
            }`}
          >
            <h2>
              {caseType}
              <input
                type="checkbox"
                style={{ height: "15px", width: "15px" }}
                name={caseType}
                checked={feedback[idx].flag}
                onChange={handleCheckbox}
              />
            </h2>
            {resultCases.has(caseType) && (
              <>
                <p>
                  {
                    results.find((result) => result.case === caseType)
                      .explanation
                  }
                </p>
                <hr />
                <p>
                  Rules:{" "}
                  {
                    results.find((result) => result.case === caseType)
                      .refs.map((rule) => `${rule}, `)
                  }
                </p>
              </>
            )}
          </div>
        ))}
      </div>
      <button
        className="button"
        onClick={handleSubmitFeedback}
        disabled={loading}
      >
        {loading ? "Recording Feedback..." : "Record Feedback"}
      </button>
    </>
  );
};
